import React from "react";
import { useState, useEffect } from "react";
import "./ActionCard.css";
import MarketMove from "./marketMove/MarketMove";
import Draggable from "react-draggable";


type ActionData = {
    analystUserId: String,
    brandname: String,
    city: String,
    countryCode: String,
    county: String,
    displayname: String,
    dma: String,
    dmaCode: String,
    dslPrice: String,
    dslPriceValidFrom: String,
    iconid: String,
    importcode: String,
    isCompetitor: Boolean,
    isdiesel: Boolean,
    location: {
        lat: Number,
        lon: Number
    },
    managerUserId: String,
    market: String,
    opisId: String,
    postype: String,
    priceSource: String,
    pricePush: {
        diesel: { price: number, exportedDate: String, effectiveDate: String }
        plus: { price: number, exportedDate: String, effectiveDate: String }
        regular: { price: number, exportedDate: String, effectiveDate: String }
        supreme: { price: number, exportedDate: String, effectiveDate: String }
    }
    regPrice: String,
    regPriceValidFrom: String,
    regularPrice: Number,
    seiid: String,
    state: String,
    zipCode: String,
    zone: String
}

interface ActionCardProps {
    listviewSelectedStores: ActionData[];
    extractedStoreCount: number;
}

//const ActionCard: React.FC<Props> = ({
//    actionCardPropsClose,
//    actionCardProps,
//}: Props) => {
const ActionCard = ({ customProps }) => {
    const [selectedProps, setSelectedProps] = useState<ActionCardProps>();

    useEffect(() => {
        customProps?.compRef?.mapLayerService?.openMarketMoveWindow$?.subscribe(
            (data: ActionCardProps) => {
                setSelectedProps(data);
            }
        );
    }, []);

    console.debug("visops-market-move: set props ", selectedProps ? selectedProps : "Loading...");
    const showPosition = { x: 800, y: 100 };
    const defaultPosition = { x: 0, y: 0 };
    const [fullscreen, setFullscreen] = useState<String>("fullscreen");
    const [fullscreenIcon, setFullscreenIcon] = useState<String>("fa-compress");
    const [position, setPosition] = useState(defaultPosition);
    const [dragCursor, setDragCursor] = useState<boolean>(false);
    const [overlay, setOverlay] = useState<String>("overlay-default");
    const [base, setBase] = useState<String>("base-default");
    const [oscillator, setOscillator] = useState<number>(0);

    function toggleFullScreen() {
        if (fullscreen === 'fullscreen') {
            setPosition(showPosition);
            setFullscreenIcon('fa-expand');
            setFullscreen('');
        } else {
            setPosition(defaultPosition);
            setFullscreenIcon('fa-compress');
            setFullscreen('fullscreen');
        }
    }

    const handleOnDrag = (e, ui) => {
        const { x, y } = ui;
        setPosition({ x, y });
    }

    const handleOnStart = () => {
        setDragCursor(true);
    }

    const handleOnEnd = () => {
        setDragCursor(false);
    }

    function toggleThemes() {
        const currentNumber = (oscillator + 1) % 4;
        switch (currentNumber) {
            case 0:
                setOscillator(currentNumber);
                setBase("base-default");
                setOverlay("overlay-default");
                break;
            case 1:
                setOscillator(currentNumber);
                setBase("base-green");
                setOverlay("overlay-green");

                break;
            case 2:
                setOscillator(currentNumber);
                setBase("base-orange");
                setOverlay("overlay-orange");
                break;
            case 3:
                setOscillator(currentNumber);
                setBase("base-red");
                setOverlay("overlay-red");
                break;
        }
    }

    return (
        <Draggable
            handle='.popup-header'
            bounds={{ left: 0, top: 0, right: 1000, bottom: 720 }}
            defaultPosition={defaultPosition}
            position={position}
            onDrag={handleOnDrag}
            onStart={handleOnStart}
            onStop={handleOnEnd}
            disabled={fullscreen === 'fullscreen' ? true : false}
            grid={[30, 30]}
        >
            <div className={`list-view-action-card-popup ${fullscreen} ${base}`}>
                <div className={`popup-header ${fullscreen === 'fullscreen' ? "" : dragCursor ? "market-move-popup-header-grabbing" : "market-move-popup-header-grab"}`}>
                    <div className='popup-title'>Market Move</div>
                    <div style={{ minWidth: '64px', display: 'flex', justifyContent: 'space-between' }}>
                        {/* <span><i style={{ color: '#fff' }} className="fas fa-tachometer market-move-close" onClick={toggleThemes} /></span> */}
                        <span><i style={{ color: '#ffc107' }} className={`fas ${fullscreenIcon} market-move-close`} onClick={toggleFullScreen} title="Toggle fullscreen" /> </span>
                        <span><i style={{ color: '#dc3545' }} className='fas fa-times-circle market-move-close' onClick={customProps.compRefClose} title="Close the Action Panel" /></span>

                    </div>
                </div>
                {selectedProps &&
                    <div className={`market-move-main-section market-move-background ${overlay}`}>
                        <div className='message'>
                            <i className="fas fa-lg fa-info-circle" aria-hidden="true" title="New Action Page for List view coming soon..." style={{ cursor: 'default', color: '#17a2b8' }}>
                                New Action Page for List view coming soon...
                            </i>
                        </div>
                        <div className="popup-market-move-content">
                            <MarketMove {...selectedProps} />
                        </div>
                    </div>
                }
                {selectedProps &&
                    <div className='popup-footer'> {`Extracted rows ${selectedProps.extractedStoreCount}`}</div>
                }
            </div>
        </Draggable>

    );
};

export default ActionCard;