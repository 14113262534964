import React from 'react';


interface ActionData{
    importcode: String,
    displayname: String,
    countryCode: String,
    priceSource: String
}

type ActionCardProps = {
    listviewSelectedStores: ActionData[];
    extractedStoreCount:number;
}



const MarketMove:React.FC<ActionCardProps> = ({ 
    listviewSelectedStores,
    extractedStoreCount } : ActionCardProps) => {

    return (
            <>
                {
                    listviewSelectedStores.map((item,index) => (
                        <div key={index} className='popup-content-data-card'>
                            <div> <b>Store Id : </b>{item.importcode}</div>
                            <div> <b>Display Name : </b>{item.displayname}</div>
                            <div> <b>Country code : </b>{item.countryCode}</div>
                            <div> <b>Price Source : </b>{item.priceSource}</div>
                        </div>
                    ))
                }
            </>
    );
};

export default MarketMove;